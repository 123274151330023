import _module from 'module.js'

_module.directive('uniqueJiveId', function (User, $q) {
  return {
    restrict: 'A',
    scope: {
      originalJiveId: '=originalJiveId',
      jiveId: '=jiveId',
    },
    require: 'ngModel',
    link: function (scope, $input, attrs, ngModelCtrl) {
      var userApi = new User(null)

      ngModelCtrl.$asyncValidators.uniqueJiveId = (model, view) => {
        var value = (model || view || '').toLowerCase()
        var dfd = $q.defer()

        if (!value || value.length === 0) {
          dfd.resolve(true)
        }

        if (!scope.originalJiveId || scope.originalJiveId !== value) {
          if (value) {
            userApi.getByEmailAddress(value).then(
              resp => {
                if ((ngModelCtrl.$name === 'userEmail' && value === scope.jiveId) || !resp.items.length) {
                  dfd.resolve(true);
                }

                dfd.reject('exists')
              },
              err => {
                $q.reject(err)
              }
            )
          }
        } else {
          dfd.resolve(true)
        }
        return dfd.promise
      }
    }
  }
})
