import _module from 'module.js'
import { compare } from 'fast-json-patch'
import _ from 'lodash'

class HotDeskingService {
  constructor (Restangular, portalConfig, $q) {
    'ngInject'
    this.api = Restangular.withConfig(restangularConfig => {
      restangularConfig.setBaseUrl(portalConfig.hotDeskingApiUrl)
    })
    this.$q = $q
  }

  getUserConfig (userId) {
    return this.api
      .one('user-config')
      .one(userId)
      .get()
      .then(data => data.plain())
  }

  updateUserConfig (existingConfig, updatedConfig) {
    if (!_.isEmpty(existingConfig) && !_.isEmpty(updatedConfig)) {
      const patch = compare(existingConfig, updatedConfig)

      if (patch.length > 0 && existingConfig.userId) {
        return this.api
          .one('user-config')
          .one(existingConfig.userId)
          .patch(patch)
          .then(data => data.plain())
      } else {
        return this.$q.when(existingConfig)
      }
    } else {
      return this.$q.when(existingConfig)
    }
  }

  getUserAgentOptions (userAgentId) {
    return this.api
      .one('user-agent-options')
      .one(userAgentId)
      .get()
      .then(data => data.plain())
  }

  updateUserAgentOptions (existingConfig, updatedConfig) {
    if (!_.isEmpty(existingConfig) && !_.isEmpty(updatedConfig)) {
      const patch = compare(existingConfig, updatedConfig)

      if (patch.length > 0 && existingConfig.userAgentId) {
        return this.api
          .one('user-agent-options')
          .one(existingConfig.userAgentId)
          .patch(patch)
          .then(data => data.plain())
      } else {
        return this.$q.when(existingConfig)
      }
    } else {
      return this.$q.when(existingConfig)
    }
  }
}

_module.service('hotDeskingService', HotDeskingService)
