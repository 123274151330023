import { applyPatch, compare } from 'fast-json-patch'

export default ($q, portalConfig) => {
  'ngInject'
  const userConfigRegex = new RegExp(
    `${
      portalConfig.hotDeskingApiUrl
    }/user-config/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})`
  )
  const userAgentOptionsRegex = new RegExp(
    `${
      portalConfig.hotDeskingApiUrl
    }/user-agent-options/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})`
  )

  const userConfigs = {}
  const userConfigCache = {
    get (id) {
      return userConfigs[id]
    }
  }

  const ensureCleanUserCacheKey = userId => {
    if (!userConfigs[userId]) {
      userConfigs[userId] = {
        userId: userId,
        roamingUacId: null,
        enabled: false
      }
    } else if (userConfigs[userId].plain) {
      userConfigs[userId] = userConfigs[userId].plain()
    }
  }

  const userAgentOptions = {}
  const userAgentOptionsCache = {
    get (id) {
      return userAgentOptions[id]
    }
  }

  const ensureCleanUserAgentCacheKey = userAgentId => {
    if (!userAgentOptions[userAgentId]) {
      userAgentOptions[userAgentId] = {
        userAgentId: userAgentId,
        enabled: false
      }
    } else if (userAgentOptions[userAgentId].plain) {
      userAgentOptions[userAgentId] = userAgentOptions[userAgentId].plain()
    }
  }

  return {
    request: function (requestConfig) {
      let resourceId
      let cache
      let cachedData

      if (userConfigRegex.test(requestConfig.url)) {
        const match = userConfigRegex.exec(requestConfig.url)
        resourceId = match[1]

        ensureCleanUserCacheKey(resourceId)

        cache = userConfigCache
        cachedData = userConfigs[resourceId]
      } else if (userAgentOptionsRegex.test(requestConfig.url)) {
        const match = userAgentOptionsRegex.exec(requestConfig.url)
        resourceId = match[1]

        ensureCleanUserAgentCacheKey(resourceId)

        cache = userAgentOptionsCache
        cachedData = userAgentOptions[resourceId]
      } else {
        return requestConfig
      }

      if (requestConfig.method === 'GET') {
        requestConfig.url = resourceId
        requestConfig.cache = cache
      } else if (requestConfig.method === 'PATCH') {
        // Does in-place apply
        const patch = compare(cachedData, requestConfig.data)
        applyPatch(cachedData, patch)

        requestConfig.url = resourceId

        return $q.reject({
          intercepted: true,
          data: cachedData,
          config: requestConfig
        })
      }
      return requestConfig
    },

    responseError: function (error) {
      if (error.intercepted) {
        return $q.when({
          data: error.data,
          status: 200,
          statusText: 'OK',
          headers: () => {},
          config: error.config
        })
      } else {
        return $q.reject(error)
      }
    }
  }
}
